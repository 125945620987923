<template>
  <div v-if="staff">
    <div class="bg-primary-50 px-4 py-5 flex flex-col">
      <div class="clearfix border-b border-secondary-500 pb-2 mb-3">
        <div class="float-left text-lg text-bold font-serif">
          {{ staff.name }}
        </div>
        <div class="float-right">
          <button class="btn-blue-outline font-bold" @click="show()">Ändra</button>
        </div>
      </div>
      <div class="flex flex-wrap text-sm">
        <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Namn</div>
          <div class="mb-1">{{ staff.name }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Email</div>
          <div class="mb-1">{{ staff.email }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Telefon</div>
          <div class="mb-1">{{ staff.phone }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Födelsedatum</div>
          <div class="mb-1">{{ formatDate(staff.birth_date) }}</div>
        </div>
        <div v-if="staff.type != 'tech'" class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Bank</div>
          <div class="mb-1">{{ staff.bank_name }}</div>
        </div>
        <div v-if="staff.type != 'tech'" class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">ICE</div>
          <div class="mb-1">{{ staff.bank_identifier }}</div>
        </div>
        <div v-if="staff.type != 'tech'" class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
          <div class="font-bold mb-1 font-serif">Kontonummer</div>
          <div class="mb-1">{{ staff.bank_account_number }}</div>
        </div>
      </div>
    </div>

    <div class="bg-primary-50 px-4 py-5 flex flex-col mt-5">
      <div class="clearfix">
        <div class="float-left text-lg text-bold font-serif">
          Lösenord
        </div>
        <div class="float-right">
          <button class="btn-blue-outline font-bold" @click="$modal.show('modal-change-password')">Ändra</button>
        </div>
      </div>
    </div>

    <StaffUpdate :staffInfo="updateInfo" @updateStaff="getStaff()" />
  </div>
</template>

<script>
import StaffUpdate from "@/components/modal/staff_update";

export default {
  name: "StaffProfile",
  title() {
    return `Inställningar | SecurCloud`;
  },
  components: {
    StaffUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      id: this.$route.params.id,
      staff: null,
      updateInfo: null,
    };
  },
  methods: {
    async getStaff() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/staff/${this.id}`);
        this.staff = response.data.data;

        this.setPageTitle(`${this.staff.name} - Inställningar`, "seller_dashboard");
      } catch (error) {
        this.handleError(error);
      }
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },

    show() {
      this.updateInfo = _.cloneDeep(this.staff);
      this.$modal.show("update-staff");
    },
  },

  created() {
    this.getStaff();
  },
};
</script>
